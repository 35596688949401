<template>
  <d-container fluid>
    <d-row>
      <main-sidebar />
      <d-col class="main-content offset-lg-2 offset-md-3 p-0" tag="main" lg="10" md="9" sm="12">
        <main-navbar/>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
// Main layout components
import MainNavbar from '../components/layout/MainNavbar/MainNavbar.vue';
import MainSidebar from '../components/layout/MainSidebar/MainSidebar.vue';

export default {
  name: 'notifications',
  components: {
    MainNavbar,
    MainSidebar
  }
}
</script>
