<template>
    <aside :class="['main-sidebar', 'col-12', 'col-md-3', 'col-lg-2', 'px-0', sidebarVisible ? 'open' : '']">
        <div class="main-navbar">
            <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
                <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
                    <div class="d-table m-auto">
                        <img id="main-logo" class="d-inline-block align-top mr-1" style="min-width: 150px;"
                             src="@/assets/images/vaartland-logo.svg" alt="Shards Dashboard">
                    </div>
                </a>
                <a class="toggle-sidebar d-sm-inline d-md-none d-lg-none" @click="handleToggleSidebar()">
                    <i class="material-icons">&#xE5C4;</i>
                </a>
            </nav>
        </div>

        <form action="#" class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
            <div class="input-group input-group-seamless ml-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fas fa-search"></i>
                    </div>
                </div>
                <input class="navbar-search form-control" type="text" placeholder="Search for something..."
                       aria-label="Search">
            </div>
        </form>

        <div class="nav-wrapper">
            <d-nav class="flex-column">
                <li v-for="(item, navItemIdx) in menu" :key="navItemIdx" class="nav-item dropdown">
                    <d-link :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']"
                            @click.native="item.hasOwnProperty('to') ? goTo(item.to.route) : false"
                            v-d-toggle="`snc-${navItemIdx}`">
                        <div class="item-icon-wrapper" v-if="item.htmlBefore" v-html="item.htmlBefore"/>
                        <span v-if="item.title">{{ item.title }}</span>
                        <div class="item-icon-wrapper" v-if="item.htmlAfter" v-html="item.htmlAfter"/>
                    </d-link>
                    <d-collapse v-if="item.items && item.items.length" :id="`snc-${navItemIdx}`"
                                class="dropdown-menu dropdown-menu-small" accordion="sidebar-items-accordion">
                        <d-dropdown-item v-for="(subItem, subItemIdx) in item.items" :key="subItemIdx"
                                         :href="subItem.href"
                                         @click.native="goTo(subItem.to)">
                            {{ subItem.title }}
                        </d-dropdown-item>
                    </d-collapse>
                </li>
            </d-nav>
            <span class="version-class">Version: {{ currentVersion }}</span>
        </div>
    </aside>
</template>

<script>
import Config from '@/config'

export default {
    name: 'main-sidebar',
    data() {
        return {
            sidebarVisible: false,
            menu: []
        };
    },
    created() {
        this.$eventHub.$on('toggle-sidebar', this.handleToggleSidebar);
        this.$eventHub.$on('menu', (e) => {
            this.menu = e
        });
    },
    beforeDestroy() {
        this.$eventHub.$off('toggle-sidebar');
    },
    methods: {
        handleToggleSidebar() {
            this.sidebarVisible = !this.sidebarVisible;
        },
        goTo(route) {
            this.$router.push({name: route}).catch((e) => {
                if (window.location.pathname !== this.$router.resolve({name: route}).href) {
                    this.goTo(route)
                } else {
                    window.location.reload()
                }
            })
        }
    },
    computed: {
        currentVersion() {
            return Config.version
        }
    }
};
</script>

<style lang="scss">
.nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-sidebar {
    .item-icon-wrapper {
        display: inline-block;
    }

    .dropdown-menu {
        display: block;
    }
}

.version-class {
    height: 30px;
    margin: 10px 0 10px 20px;
    color: lightgray;
}
</style>
