<template>
  <d-form class="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
  </d-form>
</template>

<script>
export default {
  data() {
    return {
      search: null
    }
  },
}
</script>