<template>
  <d-navbar-nav class="border-left flex-row">
    <li class="nav-item border-right dropdown notifications">
      <a class="nav-link nav-link-icon text-center" v-d-toggle.notifications>
        <div class="nav-link-icon__wrapper">
          <i class="material-icons">&#xE7F4;</i>
          <d-badge pill theme="danger">{{ getNotifications.length }}</d-badge>
        </div>
      </a>
      <d-collapse id="notifications" class="dropdown-menu dropdown-menu-small">
        <d-dropdown-item v-for="notification in getNotifications" href="#" :key="notification.id">
          <div class="notification__icon-wrapper">
            <div class="notification__icon">
              <i class="material-icons">{{ notification.icon }}</i>
            </div>
          </div>
          <div class="notification__content">
            <span class="notification__category">{{ notification.category }}</span>
            <p>{{ notification.message }}</p>
          </div>
        </d-dropdown-item>
        <d-dropdown-item class="notification__all text-center">
          <router-link to="/notifications">Alle notificaties</router-link>
        </d-dropdown-item>
      </d-collapse>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle text-nowrap px-3" v-d-toggle.user-actions>
        <img class="user-avatar rounded-circle mr-2" :src="getProfilePicture" alt="User Avatar"> <span
          class="d-none d-md-inline-block">{{ getFullName }}</span>
      </a>
      <d-collapse id="user-actions" class="dropdown-menu dropdown-menu-small">
        <d-dropdown-item @click.native="$router.push({name: 'profile'})"><i class="material-icons">&#xE7FD;</i> Profile
        </d-dropdown-item>
        <d-dropdown-divider/>
        <d-dropdown-item href="#" class="text-danger" @click.native="logout">
          <i class="material-icons text-danger">&#xE879;</i> Logout
        </d-dropdown-item>
      </d-collapse>
    </li>
  </d-navbar-nav>
</template>

<script>
export default {
  name: '',
  computed: {
    getFullName: {
      get() {
        return this.$store.getters['user/fullName']
      }
    },
    getProfilePicture: {
      get() {
        return this.$store.getters['user/profilePicture']
      }
    },
    getNotifications: {
      get() {
        return this.$store.getters['notification/notifications']
      }
    }
  },
  methods: {
    async logout() {
      let isLoggedOut = await this.$store.dispatch('user/logout')
      if (isLoggedOut) {
        this.$router.push({name: 'login'})
      }
    }
  }
}
</script>
<style>
.nav-link:hover {
  cursor: pointer;
}
</style>
